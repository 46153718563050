import {
  Box,
  Card,
  CircularProgress,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import { ENTITY_STATUSES, PAGE_LIMIT } from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"
import {
  clearPredictiveAudiences,
  fetchPredictiveAudiences,
  setPredictiveAudiencesLoading,
} from "../../../../../redux/actions/predictiveAudienceActions"

import { Alert } from "@material-ui/lab"
import Header from "../../../../NewHeader"
import { Search } from "react-feather"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import styles from "./styles"
import { useSelector } from "react-redux"

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Predictive Audiences"
  const headerButtons = []

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Predictive Audiences",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const PredictiveAudienceList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const predictiveAudiencesListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]

  const sortOptions = [
    { value: "predictiveLogicalId|DESC", label: "Predictive Logical Id (descending)" },
    { value: "predictiveLogicalId|ASC", label: "Predictive Logical Id (ascending)" },
    { value: "name|DESC", label: "Name (descending)" },
    { value: "name|ASC", label: "Name (ascending)" },
    {
      value: "createdAt|DESC",
      label: "Date Created (descending)",
    },
    {
      value: "createdAt|ASC",
      label: "Date Created (ascending)",
    },
    {
      value: "updatedAt|DESC",
      label: "Date Updated (descending)",
    },
    {
      value: "updatedAt|ASC",
      label: "Date Updated (ascending)",
    },
  ]

  const [query, setQuery] = useState("")

  const [predictiveAudienceStatusFilter, setPredictiveAudienceStatusFilter] = useState(
    ENTITY_STATUSES.ACTIVE
  )

  const [sortByFilter, setSortByFilter] = useState({
    value: "createdAt",
    order: "DESC",
  })

  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const isLoading = useSelector((state: RootState) => state.predictiveAudiences.isLoading)
  const predictiveAudiences = useSelector((state: RootState) => state.predictiveAudiences.data)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)
  const pager = useSelector((state: RootState) => state.predictiveAudiences.pager)

  const dispatchFetchPredictiveAudiences = (value = query) => {
    const fetchPredictiveAudiencesArgsObj = {
      pagination: { page: selectedPage, limit: pageLimit },
      sort: { target: sortByFilter.value, order: sortByFilter.order },
      filters: { term: value, status: predictiveAudienceStatusFilter },
    }

    predictiveAudienceStatusFilter === ENTITY_STATUSES.ALL &&
      delete fetchPredictiveAudiencesArgsObj.filters.status

    dispatch(fetchPredictiveAudiences(fetchPredictiveAudiencesArgsObj))
  }

  const debounceSearch = useCallback(debounceFn(dispatchFetchPredictiveAudiences), [
    pageLimit,
    sortByFilter,
    predictiveAudienceStatusFilter,
  ])

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }

  const handleFilterStatusChange = (e) => {
    setPredictiveAudienceStatusFilter(e.target.value)
    setSelectedPage(0)
  }

  const handleSortChange = (e) => {
    const [sortValue, orderValue] = e.target.value.split("|")
    setSortByFilter({ value: sortValue, order: orderValue })
    setSelectedPage(0)
  }

  const handlePageChange = (e, newPage) => setSelectedPage(newPage)

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value)
    setSelectedPage(0)
  }

  useEffect(() => {
    return () => {
      dispatch(clearErrors())
      dispatch(clearPredictiveAudiences())
    }
  }, [])

  useEffect(() => {
    dispatch(setPredictiveAudiencesLoading(true))
    dispatchFetchPredictiveAudiences()
  }, [pageLimit, sortByFilter, predictiveAudienceStatusFilter, selectedPage])

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}

        <Card data-testid="predictive-audience-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <Search />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search Predictive Audiences"
              value={query}
              variant="outlined"
              data-testid={"predictive-audiences-list-search-input"}
            />

            <TextField
              label="Filter By Status"
              name="statusFilter"
              className={classes.audienceStatusField}
              onChange={handleFilterStatusChange}
              select
              SelectProps={{ native: true }}
              value={predictiveAudienceStatusFilter}
              variant="outlined"
              inputProps={{
                "data-testid": "predictive-audiences-list-status-filter-input",
              }}
            >
              {predictiveAudiencesListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              label="Sort By"
              name="sort"
              className={classes.predictiveAudienceSortByField}
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"predictive-audiences-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid={"predictive-audiences-list-pagination"}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <Box className={classes.scrollTable}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell data-testid={"predictive-audiences-list-id-table-column-header"}>
                    Predictive Logical ID
                  </TableCell>
                  <TableCell data-testid={"predictive-audiences-list-name-table-column-header"}>
                    Name
                  </TableCell>
                  <TableCell data-testid={"predictive-audiences-list-status-table-column-header"}>
                    Status
                  </TableCell>
                  <TableCell
                    data-testid={"predictive-audiences-list-created-at-table-column-header"}
                  >
                    Created On
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={11} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody data-testid={"predictive-audiences-list-content"}>
                  {predictiveAudiences.map((predictiveAudience) => {
                    return (
                      <TableRow hover key={predictiveAudience.predictiveLogicalId}>
                        <TableCell
                          data-testid={`predictive-audiences-list-id-${predictiveAudience.predictiveLogicalId}`}
                        >
                          {predictiveAudience.predictiveLogicalId}
                        </TableCell>
                        <TableCell
                          data-testid={`predictive-audiences-list-name-${predictiveAudience.predictiveLogicalId}`}
                        >
                          {predictiveAudience.name}
                        </TableCell>

                        <TableCell
                          data-testid={`predictive-audiences-list-status-${predictiveAudience.predictiveLogicalId}`}
                        >
                          <small
                            className={clsx({
                              [classes.label]: true,
                              [classes.audienceStatusActive]:
                                predictiveAudience.status === ENTITY_STATUSES.ACTIVE,
                              [classes.audienceStatusInactive]:
                                predictiveAudience.status === ENTITY_STATUSES.INACTIVE,
                            })}
                          >
                            {predictiveAudience.status}
                          </small>
                        </TableCell>

                        <TableCell
                          data-testid={`predictive-audiences-created-at-id-${predictiveAudience.predictiveLogicalId}`}
                        >
                          {dayjs(predictiveAudience.createdAt).format("LLL")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              )}
            </Table>
          </Box>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid={"predictive-audiences-list-pagination"}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
    </>
  )
}

export default withStyles(styles)(PredictiveAudienceList)
