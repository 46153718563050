import { API, API_ROOT, SECTION } from "../../constants"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  fetchDealExternalAudiences,
  fetchDealExternalAudiencesFailed,
  fetchDealExternalAudiencesSuccess,
} from "../actions/dealActions"
import { handleAxiosRequest, handleSagaError } from "../../helpers/sagaHelpers"

import { AxiosRequestConfig } from "axios"
import { FetchListPayload } from "../types/sharedTypes"
import { PayloadAction } from "@reduxjs/toolkit"
import { SagaIterator } from "redux-saga"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* fetchDealExternalAudiencesSaga(action: PayloadAction<FetchListPayload>) {
  const ajaxConfig: AxiosRequestConfig = {
    method: "POST",
    url: `${API_ROOT}${API.V1}${SECTION.TOOLS}/deals/external-audiences/find`,
    data: action.payload,
  }
  try {
    const response = yield call(handleAxiosRequest, ajaxConfig)
    yield put(fetchDealExternalAudiencesSuccess(response.data))
  } catch (err) {
    yield handleSagaError(fetchDealExternalAudiencesFailed, err)
  }
}

export function* watchFetchDealExternalAudiences(): SagaIterator {
  yield takeEvery(fetchDealExternalAudiences, fetchDealExternalAudiencesSaga)
}
